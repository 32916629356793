import { Paper, Grid, TextField, MenuItem , FormControl, InputLabel, Select, Fab, Button, Typography, Divider, Tooltip, FormGroup, FormControlLabel, Switch} from '@mui/material'
import React, { useContext, useState, useEffect } from 'react'
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import { actualizarEstadoCotizacion, nuevoInputIAFNace, setDatosCotizacion, setMultisitios, updateCotizacion } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { SolicitudContext } from 'context/solicitud/solicitudContext';
 
import ModalMotivoRechazo from 'components/ModalMotivoRechazo/ModalMotivoRechazo';
import { Box } from '@mui/system';
import { PERMISOS, TIPO_ESTADO } from 'constants/constants';
import { usePermitirEdicion } from 'hooks/usePermitirEdicion';
import InputCodigos from 'components/inputIAF_Nace';
import { Add } from '@mui/icons-material';
import Restricted, { RESTRICT_METHOD } from 'components/Restricted';
import ModalFechaEnviada from '../ModalFechaEnviada';
import ModalFechaAceptada from '../ModalFechaAceptada';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useDispatch } from 'react-redux';

function PaperDatosCotizacion() {

    const dispatchRedux = useDispatch()
    const {stateSolicitud} = useContext(SolicitudContext)

    const {state, dispatch} = useContext(CotizacionContext);
    const {idSolicitud, listaCotizaciones} = stateSolicitud
    const {inputCodigosIAFNACE, inputDatosCotizacion, json, multisitios, inputIntegracion, inputNormasMotivos, estadoCotizacion, estadoCarga} = state

    const [modoEdicion, setModoEdicion] = useState(false)
    //HOOK
    const permitirEdicion = usePermitirEdicion(modoEdicion)

    const nombreRepetido = listaCotizaciones.some(c => c.nombre.toUpperCase() === inputDatosCotizacion.nombre.toUpperCase()) && json.id === ''

    const mantenimientoEditable = () => {
        if(permitirEdicion){
            return true
          }else{
            if(estadoCotizacion.estado === TIPO_ESTADO.BORRADOR){
                return false
            }else{
                return true
            }
          }
    }
    const handleNombreCotizacion = (e) => {
        dispatch(setDatosCotizacion({...inputDatosCotizacion, nombre: e.target.value}))
    }
    const handleSeguimientos = (e) => {
        if(json.cantidadMantenimientos !== e.target.value){
            alert('Usted cambio los mantenimientos, esto afectara a los calculos posteriores')
        }
        dispatch(setDatosCotizacion({...inputDatosCotizacion, seguimientos: e.target.value}))
    }

    const handleEditar = () => {
        
        if(modoEdicion){
            guardarCambios()
            setModoEdicion(false)
        }
        else{
            setModoEdicion(true)
        }
    }

    const handleChangeEstadoCotizacion = (e) =>{
        const estado = e.target.value
        switch(estado){
            case TIPO_ESTADO.ENVIADA:{
                handleModalFechaEnviada()
                break;
            }
            case TIPO_ESTADO.ACEPTADA:{
                handleModalFechaAceptada()
                break;
            }
            case TIPO_ESTADO.RECHAZADA:{
                handleModal()
                break;
            }
            default:{
                const data = {
                    idCotizacion: json.id,
                    estado: e.target.value,
                    posiblesTransiciones: null
                }
                actualizarEstadoCotizacion(dispatch, data)
                break;
            }
        }
    }  
    const getColorEstadoCotizacion = (estado) => {
        switch(estado) {
            case TIPO_ESTADO.ACEPTADA: {
                return 'success';
            }
            case TIPO_ESTADO.ENVIADA: {
               return 'warning';
            }
            case TIPO_ESTADO.DESCARTADA: {
                return 'error';
             }
             case TIPO_ESTADO.RECHAZADA: {
                return 'error';
             }
            default: {
                return ;
            }
        }
    }
    const guardarCambios = () => {
        const listaNormas = inputNormasMotivos.map(
            function(val){
                let idAux = 0
                let fechaVencimientoAux = null
                if(val.motivo === "RECERTIFICACION"){
                    fechaVencimientoAux = val.vencimiento
                }

                if(val.id){
                    idAux = val.id
                }
                return {
                    id:idAux,
                    integrada: val.integrada,
                    norma:val.norma,
                    motivo: val.motivo,
                    numeroMantenimiento:val.numero,
                    esReduccionDeAlcance: val.reduccion,
                    esAmpliacionDeAlcance: val.ampliacion,
                    esCambioDeVersion: val.cambioVersion,
                    esTransferencia: val.transferencia,
                    fechaVencimientoCertificado: fechaVencimientoAux,
                    ente:{id:val.ente}
                }
            }
        )
        let infoIntegracionAux = null;
        if(inputIntegracion.motivosOrganizacion.length>0){
            infoIntegracionAux = {...inputIntegracion, id:0}
        }

        const data = {
            id: json.id,
            idSolicitud: idSolicitud,
            nombre: inputDatosCotizacion.nombre,
            codigo: inputDatosCotizacion.codigo,
            cantidadMantenimientos: inputDatosCotizacion.seguimientos,
            normas: listaNormas,
            NACEs: inputCodigosIAFNACE.map(item => ({id: item.CodigoNace.id})),
            infoIntegracion:infoIntegracionAux,
            estado:{estado:'BORRADOR'},
            usaMultisitios: multisitios
        }
        updateCotizacion(dispatch, data);
    }

    const handleAgregarIAFNACE = () => {
        dispatch(nuevoInputIAFNace())
    }

    const handleMultisitio = (e) => {
        //zxc
        dispatch(setMultisitios(e.target.checked))
    }

    //MODAL
    const [modalState, setModalState] = useState({opened: false});
    const handleModal = () => setModalState({opened: !modalState.opened})

    //MODAL FECHA ENVIADA
    const [modalFechaEnviada, setModalFechaEnviada] = useState({opened: false});
    const handleModalFechaEnviada = () => setModalFechaEnviada({opened: !modalFechaEnviada.opened})

    //MODAL FECHA ACEPTADA
    const [modalFechaAceptada, setModalFechaAceptada] = useState({opened: false});
    const handleModalFechaAceptada = () => setModalFechaAceptada({opened: !modalFechaAceptada.opened})

    return (
            <Paper className="my_paper" elevation={5} sx={{position:"relative"}}>
                <Grid  container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                    rowSpacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Datos Cotización</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField 
                        label="Cotización" 
                        type="text" 
                        onChange={handleNombreCotizacion} 
                        fullWidth 
                        required
                        error={nombreRepetido}
                        helperText={nombreRepetido&&"Ya existe una cotizacion con el mismo nombre"}
                        value={inputDatosCotizacion.nombre}
                        disabled={permitirEdicion}></TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth>
                            <InputLabel id="id_seguimientos">Mantenimientos</InputLabel>
                            <Select
                            labelId="id_seguimientos"
                            label="Mantenimientos"
                            name="seguimientos"
                            value={inputDatosCotizacion.seguimientos}
                            onChange={handleSeguimientos}
                            disabled={mantenimientoEditable()}
                            >
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField label="Código" disabled inputProps={{readOnly: true}} value={inputDatosCotizacion.codigo}></TextField>
                    </Grid>
                    <Grid item xs={1}>
                        <FormGroup>
                            <FormControlLabel checked={multisitios} disabled={mantenimientoEditable()} control={<Switch />} onChange={handleMultisitio}  label="Multisitio" />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={2}>
                    <Button  variant="outlined" color={getColorEstadoCotizacion(estadoCotizacion.estado)} startIcon={<FiberManualRecordIcon />}>
                                {estadoCotizacion.estado}
                                </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider>Códigos IAF NACE</Divider>
                    </Grid>
                    <>
                    {permitirEdicion ?
                    <>
                    {
                        inputCodigosIAFNACE.map((input, index) => 
                        <InputCodigos 
                            key={'in'+index} 
                            valueIndex={index} 
                            inputCodigosIAFNACE={inputCodigosIAFNACE} 
                            disabled={!modoEdicion}
                            guardado={permitirEdicion}/>
                        )
                    }
                    {
                        modoEdicion &&
                        <Grid item xs={12}>
                        <Box display="flex" justifyContent={"center"}>
                            <Tooltip title="Agregar nuevo codigo IAF - NACE">
                                <Button color="primary" variant="contained" onClick={handleAgregarIAFNACE}>
                                    <Add></Add>
                                </Button>
                            </Tooltip>
                            
                        </Box>
                        </Grid>
                    }
                    </>:
                    <>
                   {
                        inputCodigosIAFNACE.map((input, index) => 
                        <InputCodigos 
                            key={'in'+index} 
                            valueIndex={index} 
                            inputCodigosIAFNACE={inputCodigosIAFNACE} 
                            disabled={false}
                            guardado={permitirEdicion}/>
                        )
                    }
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent={"center"}>
                            <Tooltip title="Agregar nuevo codigo IAF - NACE">
                                <Button color="primary" variant="contained" onClick={handleAgregarIAFNACE}>
                                    <Add></Add>
                                </Button>
                            </Tooltip>
                        </Box>
                    </Grid>
                    </>
                    }
                    </>
                    
                  
                </Grid>
                {
                    (estadoCotizacion.permiteEdicion || estadoCotizacion.estado === TIPO_ESTADO.ENVIADA) && json.id !== '' &&
                    <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}>
                    <Fab 
                            color="primary" 
                            aria-label="add" 
                            sx={{position:"absolute", right:"-70px", top:"22%"}}
                            onClick={handleEditar}
                            disabled={estadoCarga.guardando}
                        >
                        {
                            modoEdicion?
                            <SaveIcon/>
                            :
                            <EditIcon/>
                            
                        }
                        </Fab>
                    </Restricted>   
                }
                <ModalMotivoRechazo 
                    onClose={()=>handleModal()} 
                    isOpened={modalState.opened} 
                    estado={estadoCotizacion}/>
                <ModalFechaEnviada 
                    onClose={()=> handleModalFechaEnviada()}
                    isOpened={modalFechaEnviada.opened}
                />
                <ModalFechaAceptada
                    onClose={()=>handleModalFechaAceptada()}
                    isOpened={modalFechaAceptada.opened}
                />
            </Paper>  
    )
}

export default PaperDatosCotizacion

